import { createApp } from "vue";
import { createHead } from '@vueuse/head'
import { createPinia } from 'pinia'
import { createWebHistory, createRouter } from "vue-router";

// styles
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";
import "@/assets/styles/styles.css";

// mouting point for the whole app
import App from "@/App.vue";

// layouts
const Info = () => import("@/layouts/Info.vue");
const Calculator = () => import("@/layouts/Calculator.vue");
const ContactWrapper = () => import("@/layouts/ContactWrapper.vue");

// views for Calculator layout
const CalculatorDashboard = () => import("@/views/calculator/CalculatorDashboard.vue");

// views for Info layout
const Etf = () => import("@/views/info/Etf.vue");
const SP500 = () => import("@/views/info/S&P500.vue");
const NASDAQ100 = () => import("@/views/info/Nasdaq100.vue");
const CompoundInfo = () => import("@/views/info/CompoundInfo.vue");
const Contact = () => import("@/views/info/Contact.vue");
const PrivacyPolicy = () => import("@/views/info/PrivacyPolicy.vue");

const Index = () => import("@/views/Index.vue");

// routes
const routes = [
  {
    path: "/calculator",
    redirect: "/calculator/compound",
    component: Calculator,
    children: [
      {
        path: "/calculator/compound",
        component: CalculatorDashboard,
      },
      {
        path: "/calculator/etf",
        component: CalculatorDashboard,
      },
      {
        path: "/calculator/index-returns",
        component: CalculatorDashboard,
      },
      {
        path: "/calculator/big-tech-companies",
        component: CalculatorDashboard,
      },
      // {
      //   path: "/calculator/cryptocurrencies",
      //   component: CalculatorDashboard,
      // },
    ],
  },
  {
    path: "/info",
    redirect: "/info/what-is-etf",
    component: Info,
    children: [
      {
        path: "/info/what-is-etf",
        component: Etf,
      },
      {
        path: "/info/s&p500",
        component: SP500,
      },
      {
        path: "/info/nasdaq100",
        component: NASDAQ100,
      },
      {
        path: "/info/what-is-compound-calculator",
        component: CompoundInfo,
      }
    ],
  },
  {
    path: "/contact",
    redirect: "/contact",
    component: ContactWrapper,
    children: [
      {
        path: "/contact",
        component: Contact,
      }],
  },
  {
    path: "/privacy-policy",
    redirect: "/privacy-policy",
    component: ContactWrapper,
    children: [
      {
        path: "/privacy-policy",
        component: PrivacyPolicy,
      }],
  },
  {
    path: "/",
    component: Index,
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const head = createHead()

const router = createRouter({
  history: createWebHistory(),
  routes,
});

let lastCallTimestamp = Date.now();
router.afterEach((to, from) => {
  if(to.path == from.path) {
    return
  }
  var script = document.getElementById("tinyanalytics")
  if (script) {
    script.remove()
  }

  let currentTimestamp = Date.now();
  if (currentTimestamp - lastCallTimestamp < 250) return;

  setTimeout(function() {
    script = document.createElement('script')
    script.id = 'tinyanalytics'
    script.src = 'https://app.tinyanalytics.io/pixel/yDXH1HsrqPicqt88'
    script.async = true
    document.body.appendChild(script)

    lastCallTimestamp = currentTimestamp
  }, 50);
})

const pinia = createPinia()

createApp(App)
  .use(pinia)
  .use(head)
  .use(router)
  .mount("#app");
